<!-- 标题右侧带箭头 -->
<template>
  <div>
    <div class="flex_able flex-directionColumn">
      <img style="width: var(--376);height: var(--376);margin-top: var(--166);"
           src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/bcffb0e6-0180-4a31-bfad-399ef0ebc835.png"
           alt="">
      <div class="font-color666666 font-size30" style="margin-bottom: var(--376)">
        <!--   此处用于替换外界传入的文字描述     -->
        <slot></slot>
        <span v-if="!hasSlot">暂无数据</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {},
    hideImage: {}
  },
  data() {
    return {}
  },
  computed: {
    hasSlot() {
      return Boolean(this.$slots.default)
    }
  },
  methods: {}
}
</script>

<style scoped>
</style>
