<template>
  <div>
    <div style="overflow: auto;height: 100%;">
      <div v-for="(item, index) in redPackList" :key="index"
           style="position:relative;height: var(--150);display: flex;align-items: center;
          background: #FAFAFA;border-radius: var(--20);margin-bottom: var(--20);"
           class="margin-left30 margin-right30">
        <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;
          padding-left: var(--25);padding-right: var(--25);width: var(--632);">
          <van-image v-if="false" round width="12vw" height="12vw" :src="item.getUserHead"/>
          <div
              style="margin-left: var(--20);margin-right: var(--20);flex:1;font-size: var(--32);font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1B1B1B;">
            <div class="oneLine" style="max-width: var(--250);line-height: var(--44)">
              {{ item.getUserName }}
            </div>
            <div style="margin-top: var(--4);font-size: var(--30);color: #999999;line-height: var(--42)">
              {{ item.getTime }}
            </div>
          </div>
          <div
              style="display:flex;flex-direction:column;align-items:end;font-size: var(--32);font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1B1B1B;">
            <div
                style="font-size: var(--28);line-height: var(--40);margin-bottom: var(--6);text-align: right;width: 100%;">
              {{ item.amountAndCoinType }}
            </div>
            <div class="flex_center" :style="{ visibility: optimalId == item.redPacketId ? 'visible' : 'hidden'}"
                 style="font-size: var(--26);color: #026AFC;height: var(--36);">
              <img style="width: var(--26);height: var(--24);" src="@/common/img/redPack/topIcon.png" alt=""/>
              <span style="margin-left: var(--12);">
                当前手气最佳
              </span>
            </div>
          </div>
        </div>
        <div style="position:absolute;bottom:0;right: 0;width: var(--612);height: var(--2);background: #F8F8F8;"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    redPackList: {},
    optimalId: {}
  },
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  methods: {},
}
</script>

<style scoped>

</style>
